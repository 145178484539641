<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
      width="500px"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">
              {{ form.k_no == null ? 'Firma Tanımla' : 'Firma Güncelle' }}
            </h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>

          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Firma Adı" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Firma Adı">
                      <b-form-input
                        class="rounded-0"
                        id="firma_adi"
                        name="firma_adi"
                        ref="firma_adi"
                        v-model="form.firma_adi"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <validation-provider name="E Mail" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="E Mail">
                      <b-form-input
                        class="rounded-0"
                        id="e_mail"
                        name="e_mail"
                        ref="e_mail"
                        v-model="form.e_mail"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-form-group label="Telefon">
                    <b-form-input class="rounded-0" id="telefon" name="telefon" ref="telefon" v-model="form.telefon" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-form-group label="Fax">
                    <b-form-input class="rounded-0" id="fax" name="fax" ref="fax" v-model="form.fax"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label="Adres">
                    <b-form-textarea id="adres" v-model="form.adres" placeholder="Adres" class="rounded-0" rows="2" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label="Logo">
                    <b-form-file
                      class="rounded-0"
                      placeholder="Logo"
                      drop-placeholder="Lütfen Logo Seçiniz..."
                      no-drop
                      v-model="logo"
                      accept="image/jpeg, image/png, image/jpg"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between align-items-center py-50">
                <label>Domain Oluştur</label>
                <b-form-checkbox :checked="form.domain_create" v-model="form.domain_create" switch />
              </div>
              <div class="d-flex justify-content-between align-items-center py-50">
                <label>SSL Oluştur</label>
                <b-form-checkbox :checked="form.ssl_create" v-model="form.ssl_create" switch />
              </div>
              <b-row v-if="form.domain_create">
                <b-col cols="12">
                  <validation-provider name="Domain" :rules="{ required: form.domain_create }" v-slot="validationContext">
                    <b-form-group label="Domain">
                      <b-form-input
                        class="rounded-0"
                        id="domain"
                        name="domain"
                        ref="domain"
                        v-model="form.domain"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Para Birimi">
                    <v-select
                      v-model="form.kur_turu"
                      :options="kurlar"
                      :reduce="(kurlar) => kurlar.banka_kodu"
                      label="birim"
                      :clearable="false"
                      class="invoice-filter-select d-block"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="d-flex justify-content-between align-items-center py-1">
                <label>Fatura Bilgisi</label>
                <b-form-checkbox :checked="form.fatura_bilgi" v-model="form.fatura_bilgi" switch />
              </div>

              <div v-if="form.fatura_bilgi == true">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Firma Ünvan">
                      <b-form-input
                        class="rounded-0"
                        id="firma_unvan"
                        name="firma_unvan"
                        ref="firma_unvan"
                        v-model="form.firma_unvan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <b-form-group label="Vergi Dairesi">
                      <b-form-input
                        class="rounded-0"
                        id="vergi_dairesi"
                        name="vergi_dairesi"
                        ref="vergi_dairesi"
                        v-model="form.vergi_dairesi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <b-form-group :label="$t('firmalar.vergiNo')">
                      <b-form-input
                        class="rounded-0"
                        id="vergi_no"
                        name="vergi_no"
                        ref="vergi_no"
                        v-model="form.vergi_no"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Adres">
                      <b-form-textarea id="firma_adres" v-model="form.firma_adres" placeholder="Adres" rows="2" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <b-row>
                <b-col cols="12">
                  <validation-provider
                    name="Database Adı"
                    :rules="{ required: form.k_no == null && true }"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Database Adı">
                      <b-form-input
                        class="rounded-0"
                        ref="db"
                        v-model="form.db"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                        :disabled="form.k_no != null && true"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Site Türü">
                    <v-select
                      v-model="form.modul"
                      :options="modul"
                      :reduce="(modul) => modul.id"
                      label="title"
                      :clearable="false"
                      class="invoice-filter-select d-block"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Statu">
                    <v-select
                      v-model="form.statu"
                      :options="statuTitle"
                      :reduce="(statu) => statu.id"
                      label="title"
                      :clearable="false"
                      class="invoice-filter-select d-block"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form.k_no == null ? 'Ekle' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block @click="hide">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="pt-2">
                <b-col cols="12" v-if="form.k_no">
                  <b-button squared block @click="handlerRemove(form.k_no)" variant="danger" :disabled="form.statu != 2">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">Firma Sil</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import { POSITION } from 'vue-toastification';
import { ref, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    vSelect,
    Cleave,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    kurlar: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.logo = ref(null);
    expo.statuTitle = ref([
      {
        id: 1,
        title: 'Aktif',
      },
      {
        id: 2,
        title: 'Pasif',
      },
      {
        id: 3,
        title: 'Bakım Modu',
      },
    ]);

    expo.modul = ref([
      {
        id: 'tur',
        title: 'Tour Web Sitesi',
      },
      {
        id: 'emlak',
        title: 'Emlak Web Sitesi',
      },
      {
        id: 'e-ticaret',
        title: 'E Ticaret Sitesi',
      },
      {
        id: 'tanitim',
        title: 'Tanıtım Web Sitesi',
      },
    ]);
    expo.form = ref({
      k_no: null,
      firma_adi: null,
      domain: null,
      domain_create: false,
      ssl_create: false,
      e_mail: null,
      telefon: null,
      fax: null,
      adres: null,
      fatura_bilgi: false,
      firma_unvan: null,
      vergi_dairesi: null,
      vergi_no: null,
      firma_adres: null,
      kur_turu: null,
      modul: 'tur',
      db: null,
      statu: 3,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        firma_adi: null,
        domain: null,
        domain_create: false,
        ssl_create: false,
        e_mail: null,
        telefon: null,
        fax: null,
        adres: null,
        fatura_bilgi: false,
        firma_unvan: null,
        vergi_dairesi: null,
        vergi_no: null,
        firma_adres: null,
        kur_turu: null,
        modul: 'tur',
        db: null,
        statu: 3,
      };
      expo.show.value = false;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });
      if (expo.logo.value !== null) {
        formData.append('logo', expo.logo.value, expo.logo.value.name);
      }
      const basrUrl = expo.form.value.k_no == null ? 'firmaEkle' : 'firmaGuncelle';
      store.dispatch(basrUrl, formData).then((res) => {
        if (res.data.success === true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          context.emit('closeModal', false);
          if (expo.form.value.k_no == null) {
            expo.resetForm();
          }
        }
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('firmaSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Silme Başarılı',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
              context.emit('closeModal', false);
            }
          });
        }
      });
    };

    let updateData = toRefs(props).updateData;

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length > 0) expo.form.value = val;
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
