<template>
  <div class="heading-elements">
    <ul class="list-inline mb-0">
      <li v-if="availableActions.includes('export')">
        <b-button
          variant="danger"
          squared
          v-b-tooltip.hover.top="$t('global.exceleCikar')"
          :disabled="!availableActions.includes('company') && !firmaKNo"
          @click="$emit('export', true)"
        >
          <feather-icon icon="GridIcon" size="16" />
          {{ $t('global.export') }}
        </b-button>
      </li>
      <li v-if="availableActions.includes('collapse')">
        <b-button
          data-action="reload"
          :class="{ rotate: isCollapsed }"
          squared
          variant="warning"
          @click="$emit('collapse')"
          :disabled="!availableActions.includes('company') && !firmaKNo"
          v-b-tooltip.hover.top="$t('global.filtrele')"
        >
          <feather-icon icon="SearchIcon" size="16" />
        </b-button>
      </li>
      <li v-if="availableActions.includes('refresh')">
        <b-button
          data-action="reload"
          squared
          variant="success"
          @click="$emit('refresh')"
          :disabled="!availableActions.includes('company') && !firmaKNo"
          v-b-tooltip.hover.top="$t('global.yenile')"
        >
          <feather-icon icon="RotateCwIcon" size="16" />
        </b-button>
      </li>
      <li v-if="availableActions.includes('newAdd')">
        <b-button
          variant="primary"
          squared
          v-b-tooltip.hover.top="$t('global.yeniKayitEkle')"
          :disabled="!availableActions.includes('company') && !firmaKNo"
          @click="$emit('newAdd', true)"
        >
          <feather-icon icon="PlusSquareIcon" size="16" />
          {{ $t('global.ekle') }}
        </b-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
export default {
  components: {
    vSelect,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      requried: true,
    },
    availableActions: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    firmaKNo() {
      return this.$store.getters.getUserData.firma_k_no;
    },
  },
  data: () => ({
    export_select: 'xlsx',
    exportData: ['xlsx', 'csv', 'txt'],
  }),
};
</script>
