<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Firmalar"
      icon="BookIcon"
      :showLoading="show"
      :actionCollapse="true"
      :actionRefresh="true"
      :actionNewAdd="true"
      :collapsed="true"
      :actionCompanyAdd="true"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerFirmaEkle($event)"
    >
      <!-- actionCollapse true ise filtre kısmı calısırız -->
      <template v-slot:filtrele>
        <b-row class="my-1 mx-25">
          <b-col cols="12" md="6">
            <b-form-input v-model="searchTerm" class="mr-1 rounded-0" placeholder="Ara..." />
          </b-col>
          <b-col cols="12" md="6">
            <v-select
              v-model="statusFilter"
              :options="statusOptions"
              class="invoice-filter-select"
              :placeholder="$t('global.secimYapiniz')"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 100,
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'firma_adi'">
              <router-link
                :to="{
                  name: 'firma-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                {{ props.row.firma_adi }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'domain'">
              <a :href="demoUrl(props.row)" target="_blank">{{ props.row.domain }}</a>
            </span>
            <span v-else-if="props.column.field === 'tarih'">
              <b-badge variant="light-success" class="rounded-0" v-b-tooltip.hover.bottom="'Başlangıç Tarihi'">
                {{ props.row.tarih | moment }}
              </b-badge>
              |
              <b-badge variant="light-danger" class="rounded-0" v-b-tooltip.hover.bottom="'Lisans Bitiş Tarihi'">
                {{ props.row.lisans_sure | moment }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'bakiye'"
              :class="
                handlerBalance(props.row.hareketler) > 0
                  ? 'text-success'
                  : handlerBalance(props.row.hareketler) == 0
                  ? 'text-secondary'
                  : 'text-danger'
              "
            >
              {{ handlerBalance(props.row.hareketler) | cur }}
              {{ props.row.kur_turu }}
              {{ handlerBalance(props.row.hareketler) > 0 ? 'A' : handlerBalance(props.row.hareketler) == 0 ? '' : 'B' }}
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-badge :variant="`light-${statusColor(props.row.statu)}`" class="rounded-0">
                <feather-icon icon="UnlockIcon" class="mr-50" />
                {{ statusText(props.row.statu) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="py-25">
                <b-button
                  variant="success"
                  class="rounded-0"
                  @click="handlerSureUzat(props.row)"
                  v-b-tooltip.hover.bottom="'Süre Ekle & Uzat'"
                >
                  <feather-icon icon="CalendarIcon" />
                </b-button>
                <b-button
                  class="rounded-0"
                  variant="info"
                  :to="{
                    name: 'firma-detay',
                    params: { k_no: props.row.k_no },
                  }"
                  v-b-tooltip.hover.bottom="'Firma İncele'"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  variant="warning"
                  class="rounded-0"
                  @click="handlerDuzenle(props.row)"
                  v-b-tooltip.hover.bottom="'Düzenle'"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <v-select
                  v-model="pageLength"
                  :options="perPageOptions"
                  :clearable="false"
                  class="invoice-filter-select"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card-actions>

    <firma-tanimla
      v-model="openFirmaModal"
      :openModal="openFirmaModal"
      :updateData="updateData"
      :kurlar="kurlar"
      @closeModal="closeModal = $event"
    />
    <firma-sure-uzat
      :openModal="openSureModal"
      :firma="firma"
      :updateData="updateData"
      :kurlar="kurlar"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import FirmaTanimla from './component/firmaTanimla';
import { VueGoodTable } from 'vue-good-table';
import FirmaSureUzat from './component/firmaSureUzat.vue';
import { ref, computed, watch } from '@vue/composition-api';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
export default {
  components: {
    vSelect,
    BCardActions,
    FirmaTanimla,
    VueGoodTable,
    FirmaSureUzat,
  },

  setup() {
    const expo = {};
    expo.show = ref(false);
    expo.closeModal = ref(false);
    expo.firma = ref({});
    expo.updateData = ref({});
    expo.openFirmaModal = ref(false);
    expo.openSureModal = ref(false);
    expo.kurlar = ref([]);
    expo.pageLength = ref(25);
    expo.perPageOptions = ref([10, 25, 50, 100]);
    expo.columns = ref([
      {
        label: 'Firma Adı',
        field: 'firma_adi',
      },
      {
        label: 'Domain',
        field: 'domain',
      },
      {
        label: 'E Mail',
        field: 'e_mail',
      },
      {
        label: 'Telefon',
        field: 'telefon',
      },
      {
        label: 'Lisans Tarihi',
        field: 'tarih',
        tdClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'Bakiye',
        field: 'bakiye',
        width: '10%',
        tdClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '10%',
        tdClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        tdClass: 'text-right',
        thClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);
    expo.searchTerm = ref('');
    expo.statusOptions = ref(['Hepsini Göster', 'Alacaklı', 'Borçlu', 'Pasif', 'Aktif']);
    expo.statusFilter = ref('Hepsini Göster');

    expo.demoUrl = computed(() => {
      return (val) => {
        const { NODE_ENV } = process.env;
        if (NODE_ENV == 'development') {
          return `http://localhost:3002/${val.k_no}`;
        } else {
          return `http://${val.domain}`;
        }
      };
    });
    expo.statusColor = computed(() => {
      const statusColor = {
        1: 'success',
        2: 'danger',
        3: 'info',
      };

      return (value) => statusColor[value];
    });

    expo.statusText = computed(() => {
      const statusText = {
        1: 'Aktif',
        2: 'Pasif',
        3: 'Bakım Modunda',
      };

      return (value) => statusText[value];
    });

    const handlerKurlar = async () => {
      await store.dispatch('kurTurleriListele');
      expo.kurlar.value = await store.getters.getKurTurleri;
    };

    handlerKurlar();

    expo.refreshStop = (event) => {
      handlerGetData();
    };

    expo.handlerFirmaEkle = (event) => {
      expo.openFirmaModal.value = true;
    };

    expo.handlerDuzenle = (task) => {
      if (task) {
        expo.updateData.value = task;
        expo.openFirmaModal.value = true;
      }
    };

    expo.handlerSureUzat = (task) => {
      if (task) {
        expo.firma.value = task;
        expo.openSureModal.value = true;
        expo.closeModal.value = true;
      }
    };

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('firmaListele');
      expo.rows.value = await store.getters.getFirmalar;
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerBalance = (event) => {
      if (event.length > 0) {
        let giris = 0.0;
        let cikis = 0.0;
        let bakiye = 0.0;
        event.map((value) => {
          value.gc_kodu == 'G' ? (giris += value.tutar) : (cikis += value.tutar);
        });
        bakiye = giris - cikis;
        return bakiye;
      } else {
        return 0;
      }
    };

    watch(
      expo.closeModal,
      (val) => {
        if (val == false) {
          expo.openFirmaModal.value = false;
          expo.openSureModal.value = false;
          expo.closeModal.value = false;
          expo.updateData.value = {};
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
